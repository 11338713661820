import Service, { inject as service } from "@ember/service";
export default class RestrictedRouting extends Service {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  get isRestricted() {
    return this._needsRequiredFields || this._needs2fa;
  }
  isAllowedRoute(path) {
    const alwaysAllowed = ["faq", "about", "tos", "privacy"];
    if (alwaysAllowed.includes(path)) {
      return true;
    }
    if (this._needs2fa) {
      if (path === "preferences.second-factor") {
        return true;
      }
      return false;
    }
    if (this._needsRequiredFields) {
      if (path.startsWith("admin")) {
        return true;
      }
      if (path === "preferences.profile") {
        return true;
      }
      return false;
    }
    return true;
  }
  get _needs2fa() {
    // NOTE: Matches the should_enforce_2fa? and disqualified_from_2fa_enforcement
    // methods in ApplicationController.
    const enforcing2fa = this.siteSettings.enforce_second_factor === "staff" && this.currentUser.staff || this.siteSettings.enforce_second_factor === "all";
    const exemptedFrom2faEnforcement = !this.currentUser || this.currentUser.is_anonymous || this.currentUser.second_factor_enabled || !this.siteSettings.enforce_second_factor_on_external_auth && this.currentUser.login_method === "oauth";
    return enforcing2fa && !exemptedFrom2faEnforcement;
  }
  get _needsRequiredFields() {
    return this.currentUser?.needs_required_fields_check;
  }
}